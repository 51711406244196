<template>
  <managed-services />
</template>

<script>
import ManagedServices from "../../components/solutions/cloud-solutions/ManagedServices.vue";
export default {
  name: "managed-services-page",
  components: { ManagedServices },
};
</script>
